
import './style/common.scss';
import './style/mobile.scss';
import './style/font.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './component/Header';
import Footer from './component/Footer';
import Main from "./page/Main";
import About from "./page/About";
import Business from './page/Business';
import Contact from './page/Contact';
import Video from './page/Video';
function App() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route exact path="/" element={<Main/>}/>
        <Route exact path="/about" element={<About/>}/>
        <Route exact path="/business" element={<Business/>}/>
        <Route exact path="/contact" element={<Contact/>}/>
        {/* <Route exact path="/video" element={<Video/>}/> */}
      </Routes>
      <Footer/>
   </Router>
  );
}

export default App;
