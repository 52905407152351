import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
function Contact() {
   
    useEffect(()=>{  
      
        const ani_items = [...document.querySelectorAll('.contact')];
        ani_items.map((a)=>{
            gsap.fromTo(a,{duration: 2,opacity: 0,},{scrollTrigger: {
                trigger: a,
                start: 'top 60%',
            },opacity:1});
        })
       
      
      
    },[])


    return (
        <div className='contents_wrap'>
             <div className='back_img back4'><img src={"/img/back_4"+(window.innerWidth<900?'_m':'')+".svg"} /></div>
            <div className="contact c1">
                <div className='left_section'>
                    <div className='section_title'>Contact us</div>
                    <div className='map_img'>
                        <img src="/img/map.png"/>
                    </div>
                </div>
                <div className='right_section'>
                    <div className='contact_items'>
                        <div className='ct_item first_item'>
                            <div className='ct_country'>South Korea</div>
                            <div className='ct_name'>
                                <span class="strong_t">Headquarters</span> <br />
                                Sungshin New Material Co.Ltd <br />
                                <br /><span className='mobile_view'></span>
                                <span class="strong_t">R&D Center</span> <br />
                                Comtech Chemical Co.Ltd
                            </div>
                            <div className="ct_tel">
                                8-48, Bunseong-ro, 727 Rd,<br />
                                Gimhae, Gyeongnam, 50827<br />
                                T  +82 51 927 2001-2<br />
                                F  +82 51 927 2006<br />
                                E  ssc@sungshin.com
                            </div>
                        </div>
                        <div className='ct_item'>
                            <div className='ct_country'>China</div>
                            <div className='ct_name'>
                                Sungshin China <br /><span className='mobile_view'><br/></span> 
                                Qingyuan | Hexin | Qingxin | <br />
                                Qiyang | Nanchang | Guangxi
                            </div>
                            <div className="ct_tel">
                                T  +86 763 360 2688  <br />
                                F  +86 763 360 3972  <br />
                                E  ssc@sungshin.com
                            </div>
                        </div>
                        <div className='ct_item'>
                            <div className='ct_country'>Thailand</div>
                            <div className='ct_name'>
                                Thai Sungshin <br />
                                New Material Co.Ltd
                            </div>
                            <div className="ct_tel">
                                T  +66 38 961 850(3) <br />
                                F  +66 38 961 854 <br />
                                E  tss@sungshin.com
                            </div>
                        </div>
                        <div className='ct_item'>
                            <div className='ct_country'>Indonesia</div>
                            <div className='ct_name'>
                                PT. Sungshin <br />
                                PT. Sungshin A, B
                            </div>
                            <div className="ct_tel">
                                T  +62 21 820 4245(7) <br />
                                F  +62 21 820 4276 <br />
                                E  ssi@sungshin.com
                            </div>
                        </div>
                        <div className='ct_item'>
                            <div className='ct_country'>Vietnam</div>
                            <div className='ct_name'>
                                Sungshin Vietnam Co.Ltd <br />
                                Sungshin Vietnam A-I, II, III
                            </div>
                            <div className="ct_tel">
                                T  +84 8 3897 4198(9) <br />
                                F  +82 8 3897 4197 <br />
                                E  ssv@sungshin.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>

    )


}

export default Contact;
