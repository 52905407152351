import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import {gsap,Linear} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
function About() {

   
    useEffect(()=>{  
      
        const ani_items = [...document.querySelectorAll('.a1'),...document.querySelectorAll('.a2 .divider_container'),...document.querySelectorAll('.a3')];
        ani_items.map((a)=>{
            gsap.fromTo(a,{duration: 2,opacity: 0,},{scrollTrigger: {
                trigger: a,
                start: 'top 60%',
            },opacity:1});
        })
        gsap.to('.cc_1',13,{rotate:-360,ease:Linear.easeNone, repeat:-1});
        gsap.to('.cc_2',13,{rotate:360,ease:Linear.easeNone, repeat:-1});

        
        gsap.to('.circle1', {
            rotate: -360, scrollTrigger: {
                trigger: ".contents_wrap",
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "bottom bottom",
                scrub: 1
            }
        });
        gsap.to(['.circle2','.circle3'], {
            rotate: 360, scrollTrigger: {
                trigger: ".contents_wrap",
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "bottom bottom",
                scrub: 1
            }
        });
       
      
      
    },[])

    return (
        <div className='contents_wrap'>
             <div className='back_img back2'>
                <div className='back_container'>
                <span className='cc_1'><img className='circle1' src="/img/bc21.svg" /></span>
                <span className='cc_2'><img className='circle2' src="/img/bc22.svg" /></span>
                <span className='cc_2'><img className='circle3' src="/img/bc23.svg" /></span>
                </div>
            </div>
            <div className='about a1'>
                <div className="main_copy"><img src={"/img/about_copy"+(window.innerWidth<900?"_m":'')+".png"}/>
                </div>
                <div className='main_desc'>
                Since its foundation, Sungshin Global has adhered to the values of integrity, loyalty, and challenge. Our heritage, enduring partnerships, and innovative achievements continue to define us today.
                </div>
            </div>
            <div className="about a2">
                <div className='divider_container'>
                    <div className='section_title'>About</div>
                    <div className='section_desc'>
                        <div className="text_wrap">
                        Founded in 1987 in Busan, South Korea, Sungshin Global took its first step by tapping into the potential of injection molding technology and its application to manufacturing. The company successfully overcame the challenge of commercially scaling then-underutilized injection technology, transforming the production process of shoe midsoles. Today one of the world’s largest midsole providers, Sungshin is trusted by major brands across fashion and sportswear industries. Sungshin remains devoted to grow and thrive together with the global community.
                    
                        </div>
                   </div>
                </div>
                <div className='divider_container'>
                    <div className='section_title'>Heritage</div>
                    <div className='section_desc'>
                        <div className='sub_title'>
                        Pioneer of Injection <br/>Midsole Technology  
                        </div>
                        <div className="herit_items">
                            <div className='herit_item'>
                                <div className="herit_title">
                                Highest Annual <br/>
                                Sales Record
                                </div>
                                <div className='herit_contents'>
                                    <span className="herit_value">136</span>
                                    million pairs
                                </div>
                            </div>
                            <div className='herit_item'>
                                <div className="herit_title">
                                Years of Experience
                                </div>
                                <div className='herit_contents'>
                                    <span className="herit_value">35</span>
                                    <span className="herit_plus">+</span>
                                    years
                                </div>
                            </div>
                            <div className='herit_item'>
                                <div className="herit_title">
                                Global Production <br/>
                                Network
                                </div>
                                <div className='herit_contents'>
                                <span className="herit_value">16</span>
                                <span className="herit_plus">+</span>
                                    factories
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='divider_container'>
                    <div className='section_title'>Partners</div>
                    <div className='section_desc'>
                        <div className='sub_title'>
                        Long-Standing Partnerships <br/>Across the Globe
                        </div>
                        <div className="partner_container">
                            <div className="partner_items">
                                <div className="partner_title">Partners</div>
                                <div className="partner_item">Evervan Group</div>
                                <div className="partner_item">Shoetown Group</div>
                                <div className="partner_item">Freetrend</div>
                                <div className="partner_item">PT.Panarub Industry</div>
                                <div className="partner_item">Hwaseung</div>
                            </div>
                            <div className="partner_items">
                                <div className="partner_title">R&D Network</div>
                                <div className="partner_item">Formosa Plastics</div>
                                <div className="partner_item">Hanwha Chemical</div>
                                <div className="partner_item">Dow</div>
                                <div className="partner_item">Mitsui Chemicals</div>
                                <div className="partner_item">Dupont</div>
                            </div>
                            <div className='partner_imgs'>
                                <div className="pt_img"><img src="/img/partner01.png"/></div>
                                <div className="pt_img"><img src="/img/partner02.png"/></div>
                               
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='about a3'>
                <div className='q_section'>
                    <img src="/img/ic_q_top.svg" />
                    <div className='q_message'>
                    With 34 years of experience and innovative technology, we have proven ourselves to be a leading expert.             

                    </div>
                    <img src="/img/ic_q_bottom.svg" />
                    <div className='q_writer'>Lim Dae-hwi <br/>
                    Chief Executive Officer, Sungshin Global</div>
                </div>
            </div>

        </div>

    )


}

export default About;
