import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import {gsap,Linear} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

gsap.registerPlugin(ScrollTrigger);
function Business() {
    useEffect(()=>{  
      
        const ani_items = [...document.querySelectorAll('.business')];
        ani_items.map((a)=>{
            gsap.fromTo(a,{duration: 2,opacity: 0,},{scrollTrigger: {
                trigger: a,
                start: 'top 80%',
            },opacity:1});
        })
        gsap.to('.cc_1',13,{rotate:-360,ease:Linear.easeNone, repeat:-1});
        gsap.to('.cc_2',13,{rotate:360,ease:Linear.easeNone, repeat:-1});

        gsap.to('.circle1', {
            rotate: window.innerWidth>900?-360:-720, scrollTrigger: {
                trigger: ".contents_wrap",
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "bottom bottom",
                scrub: 1,
            }
        });
        gsap.to(['.circle2','.circle3'], {
            rotate: window.innerWidth>900?360:720, scrollTrigger: {
                trigger: ".contents_wrap",
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "bottom bottom",
                scrub: 1
            }
        });
      
    },[])

    return (
        <div className='contents_wrap'>
            <div className='back_img back3'>
                <div className='back_container'>
                 
                <span className='cc_1'><img className='circle2' src="/img/bc31.svg" /></span>
                <span className='cc_2'><img className='circle3' src="/img/bc32.svg" /></span>
                <span className='cc_2'> <img className='circle1' src="/img/bc33.svg" /></span>
                </div>
            </div>
            <div className="business b1">
                <div className='divider_container'>
                    <div className='section_title'>Products</div>
                    <div className='section_desc'>
                        <div className='sub_title'>
                            Delivering Best-In-Class <br />
                            Performance Capabilities
                        </div>
                        <div className='product_items'>
                            <div className="pr_item">
                                <div className='pr_name'>Under Armour <br />Hovr Foam</div>
                                <div className='pr_desc'>Brand franchise technology <br />
                                    SS18 Launch (2 models) <br />Exclusive partner in first season <br />High performance foam <br />High cushioning / energy return
                                </div>
                                <div className='pr_img'>
                                    <img src="/img/pr01.png" />
                                </div>
                            </div>
                            <div className="pr_item">
                                <div className='pr_name'>Adidas <br />Bounce Foam</div>
                                <div className='pr_desc'>
                                    50C / 55C spec In <br />
                                    40C under spec in process <br />
                                    FW14: BB category <br />
                                    6 models / 2M prs <br />
                                    SS15: Multi density midsole <br />
                                    (40C + 50C, Bounce Sole Project) <br />
                                    Original category: 25C / 35C under <br />
                                    spce in process
                                </div>
                                <div className='pr_img'>
                                    <img src="/img/pr02.png" />
                                </div>
                            </div>
                            <div className="pr_item">
                                <div className='pr_name'>Adidas <br />Yeezy Slide</div>
                                <div className='pr_desc'>
                                    Launched in 2020 <br />
                                    Neutral colorway and bulky <br />
                                    Comfortable and soft <br />
                                    Produced in Sungshin China <br />
                                    since launch
                                </div>
                                <div className='pr_img'>
                                    <img src="/img/pr03.png" />
                                </div>
                            </div>
                            <div className="pr_item">
                                <div className='pr_name'>Fila <br />Energized Foam</div>
                                <div className='pr_desc'>
                                    Look for technology for brand <br />
                                    renewal <br />
                                    Energized foam 45C + HR <br />
                                    SS15: 2 models, FW15: 6-8 models <br />
                                    Energized insole, backpack

                                </div>
                                <div className='pr_img'>
                                    <img src="/img/pr04.png" />
                                </div>
                            </div>
                            <div className="pr_item">
                                <div className='pr_name'>Under Armour <br />Charged Foam</div>
                                <div className='pr_desc'>
                                    Brand Franchise technology <br />
                                    Direct Comparison vs. <br />
                                    lunar / Boost <br />FW15 - 3 running, 2 basketball, <br />
                                    1 outdoor, 2 speedform, <br />
                                    8 models spec in <br />
                                    SSC is main partner
                                </div>
                                <div className='pr_img'>
                                    <img src="/img/pr05.png" />
                                </div>
                            </div>
                            <div className="pr_item">
                                <div className='pr_name'>Under Armour <br />Flow Foam</div>
                                <div className='pr_desc'>
                                    Curry x Under Armour <br />
                                    FW20 BB Launch <br />
                                    Exclusive partner <br />
                                    High cushioning / energy return
                                </div>
                                <div className='pr_img'>
                                    <img src="/img/pr06.png" />
                                </div>
                            </div>
                            <div className="pr_item">
                                <div className='pr_name'>New Balance <br />Fresh Foam</div>
                                <div className='pr_desc'>
                                    Fresh Foam Midsole <br />
                                    Continual cushioning no matter <br />
                                    where you come down on the <br />
                                    shoe for a smooth ride <br />
                                    A soft, plush feel, with safety <br />
                                    thanks to a microfocus of the <br />
                                    hexagonal geometries
                                </div>
                                <div className='pr_img'>
                                    <img src="/img/pr07.png" />
                                </div>
                            </div>
                            <div className="pr_item">
                                <div className='pr_name'>Allbirds <br />Tree Runner</div>
                                <div className='pr_desc'>
                                    Renewable materials <br />
                                    Feels Light and breezy <br />
                                    Sugarcane midsole, SweetFoam <br />
                                    which is made with the world’s first <br />
                                    carbon negative green EVA <br />
                                    Carbon neutral
                                </div>
                                <div className='pr_img'>
                                    <img src="/img/pr08.png" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div className='business b2'>
                <div className='divider_container'>
                    <div className='section_title'>Achievements</div>
                    <div className='section_desc'>
                        <div className='sub_title'>
                        Shaping the Future <br/>
                        with Foam Technology
                        </div>
                        <div className='tech_items'>
                            <div className="tech_item">
                                <div className='tech_number'>1</div>
                                <div className='tech_title'>
                                Invention of globally innovative injection midsole technology 
                                </div>
                                <div className='tech_contents'>
                                In 1993, Sungshin Global developed its breakthrough injection technology, an innovation which drastically improved midsole manufacturing efficiency. Reducing the formerly 20-step process down to a mere 3 steps, the technology scaled production quantity to 3,000 pairs per day while requiring only a slim personnel of under 10 employees. The novel injection technology cut raw material loss rate in half, significantly reducing the cost of production. 
                                </div>
                            </div>
                            <div className="tech_item">
                                <div className='tech_number'>2</div>
                                <div className='tech_title'>
                                Development of new injection phylon material
                                </div>
                                <div className='tech_contents'>
                                Together with its innovative injection technology, Sungshin Global developed EVA-based injection phylon materials, allowing for the creation of highly functional midsoles. Sungshin’s EVA materials attained exceptional levels of elasticity and durability. Subsequently developed TPE-based materials combined the strength of rubber with the flexibility of plastic, reaching new possibilities in midsole performance. Sungshin’s innovations in EVA and TPE materials set the stage for the mass production of injection foam midsoles.
                                </div>
                            </div>
                            <div className="tech_item">
                                <div className='tech_number'>3</div>
                                <div className='tech_title'>
                                The world’s first commercialized injection midsole 
                                </div>
                                <div className='tech_contents'>
                                Through innovations in injection technology and materials, Sungshin markedly improved midsole manufacturing efficiency, reduced production cost, and made headway into more environmentally sustainable midsoles with the use of foam. The world’s first company to commercialize injection foam midsoles, Sungshin Global has realized a milestone in the shoe industry and continues to build upon its legacy today.  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )


}

export default Business;
