import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { gsap, Linear } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
function Main() {
    const [videoPopYn,setVideoPopYn] = useState(false);
    const [videoPopYn2,setVideoPopYn2] = useState(false);
    const [mobileMode,setMobileMode]= useState(false)

    useEffect(() => {

        if(window.innerWidth<900){
            setMobileMode(true);
        }
        let ani_items = [...document.querySelectorAll('.main')];
        ani_items = [...ani_items, ...document.querySelectorAll('.a1'), ...document.querySelectorAll('.a2 .divider_container'), ...document.querySelectorAll('.a3')];
        ani_items = [...ani_items, ...document.querySelectorAll('.business')];
        ani_items = [...ani_items, ...document.querySelectorAll('.contact')];

        ani_items.map((a) => {
            gsap.fromTo(a, { duration: 3, opacity: 0, y: window.innerWidth<900?0:50 }, {
                scrollTrigger: {
                    trigger: a,
                    start: 'top 60%',
                }, opacity: 1, y: 0
            });
        })
        gsap.to('.cc_1', 13, { rotate: -360, ease: Linear.easeNone, repeat: -1 });
        gsap.to('.cc_2', 13, { rotate: 360, ease: Linear.easeNone, repeat: -1 });

        gsap.to('.circle1', {
            rotate: -600, ease: Linear.easeNone, scrollTrigger: {
                trigger: ".contents_wrap",
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "+=8000",
                scrub: 1
            }
        });
        gsap.to(['.circle2', '.circle3'], {
            rotate: 600, ease: Linear.easeNone, scrollTrigger: {
                trigger: ".contents_wrap",
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "+=8000",
                scrub: 1
            }
        });



    }, []);
    const videoPopOpen=()=>{

    }

    return (
        <div className='contents_wrap'>
            {(videoPopYn||videoPopYn2)&&<div className='pop_video_section'  onClick={()=>{setVideoPopYn(false);setVideoPopYn2(false)}} >
                {videoPopYn&&<div className='video_section' style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                    <iframe src="https://player.vimeo.com/video/728080678?background=1&autoplay=1&loop=1&muted=1&autopause=0&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>}
                {videoPopYn2&&<div className='video_section' style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                    <iframe src="https://player.vimeo.com/video/728081597?background=1&autoplay=1&loop=1&muted=1&autopause=0&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>}

            </div>}
            <div className='back_img back1'>
                <div className='back_container'>
                    <span className='cc_1'><img className='circle1' src="/img/bc11.svg" /></span>
                    <span className='cc_2'><img className='circle2' src="/img/bc12.svg" /></span>
                    <span className='cc_2'><img className='circle3' src="/img/bc13.svg" /></span>
                </div>
            </div>
            <div className='main m1'>
                <div className="main_copy">A Global Leader <span className='mobile_view'></span><br />
                    in Material <span className='mobile_view'></span>Science <br />
                    and Manufacturing
                </div>
                <div className='col2_section'>
                    <div className='section_item'>
                        <div className='section_title'>Our Values</div>
                        <div className='section_contents'>
                            Sungshin Global thrives on the values of integrity, <br />
                            loyalty, and challenge
                        </div>
                    </div>
                    <div className='spec_items'>
                        <div className='spec_item'>Integrity</div>
                        <div className='spec_item'>Loyalty</div>
                        <div className='spec_item'>Challenge</div>
                    </div>

                </div>
            </div>
            <div className='main m2'>
                <div className='q_section'>
                    <img src="/img/ic_q_top.svg" />
                    <div className='q_message'>
                        We believe integrity <br />
                        is the key to achieving <br />
                        our mission.
                    </div>
                    <img src="/img/ic_q_bottom.svg" />
                    <div className='q_writer'>B.M Lim <br />
                        Chairman, Sungshin Global</div>
                </div>
            </div>
            <div className='about a1'>
                <div className="main_copy"><img src={"/img/about_copy" + (window.innerWidth < 900 ? "_m" : '') + ".png"} />
                </div>
                <div className='main_desc'>
                    Since its foundation, Sungshin Global has adhered to the values of integrity, loyalty, and challenge. Our heritage, enduring partnerships, and innovative achievements continue to define us today.
                </div>
            </div>
            <div className="about a2">
                <div className='divider_container'>
                    <div className='section_title'>About</div>
                    <div className='section_desc'>
                        <div className="text_wrap">
                            Founded in 1987 in Busan, South Korea, Sungshin Global tapped the potential of injection molding technology and its application to manufacturing. The company commercially scaled then-underutilized injection technology, transforming the production process of shoe midsoles. Today one of the world’s largest midsole providers, Sungshin is trusted by major brands across fashion and sportswear industries. Sungshin remains devoted to grow and thrive together with the global community.
                        </div>
                    </div>
                </div>
                <div className='divider_container'>
                    <div className='section_title'>Heritage</div>
                    <div className='section_desc'>
                        <div className='sub_title'>
                            Pioneer of Injection <br />Midsole Technology
                        </div>
                        <div className="herit_items">
                            <div className='herit_item'>
                                <div className="herit_title">
                                    Highest Annual <br />
                                    Sales Record
                                </div>
                                <div className='herit_contents'>
                                    <span className="herit_value">136</span>
                                    million pairs
                                </div>
                            </div>
                            <div className='herit_item'>
                                <div className="herit_title">
                                    Years of Experience
                                </div>
                                <div className='herit_contents'>
                                    <span className="herit_value">35</span>
                                    <span className="herit_plus">+</span>
                                    years
                                </div>
                            </div>
                            <div className='herit_item'>
                                <div className="herit_title">
                                    Global Production <br />
                                    Network
                                </div>
                                <div className='herit_contents'>
                                    <span className="herit_value">16</span>
                                    <span className="herit_plus">+</span>
                                    factories
                                </div>
                            </div>
                        </div>
                        <div className='graph'>
                            <img src={"/img/graph"+(mobileMode?'_m':'')+".png"} />
                        </div>
                        
                        
                    </div>
                </div>
                <div className='divider_container'>
                    <div className='section_title'>Partners</div>
                    <div className='section_desc'>
                        <div className='sub_title'>
                            Long-Standing Partnerships <br />Across the Globe
                        </div>
                        <div className="partner_title">Partners</div>
                        <div className="partner_container">

                            <div className="partner_items">
                                <div className="partner_item">Evervan Group<img src="/img/partner01.png" /></div>
                                <div className="partner_item">Shoetown Group<img src="/img/partner02.png" /></div>
                                <div className="partner_item">Freetrend Group<img src="/img/partner03.png" /></div>
                            </div>
                            <div className="partner_items last_items">
                                <div className="partner_item">PT.Panarub Industry<img src="/img/partner04.png" /></div>
                                <div className="partner_item">Hwaseung<img src="/img/partner05.png" /></div>
                                <div className="partner_item">Dean Shoes<img src="/img/partner06.png" /></div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className='about a3'>
                <div className='q_section'>
                    <img src="/img/ic_q_top.svg" />
                    <div className='q_message'>
                        Green first, <br />
                        foam is our future.
                    </div>
                    <img src="/img/ic_q_bottom.svg" />

                    <div className='q_writer'>
                        B.M Lim  <br />
                        Chairman, Sungshin Global </div>

                </div>
            </div>
            <div className='business b2'>
                <div className='divider_container'>
                    <div className='section_title'>Achievements</div>
                    <div className='section_desc'>
                        <div className='sub_title'>
                            Shaping the Future <br />
                            with Foam Technology
                        </div>
                        <div className='tech_items'>
                            <div className="tech_item">
                                <div className='tech_number'>1</div>
                                <div className='tech_title'>
                                    Invention of globally innovative injection midsole technology
                                </div>
                                <div className='tech_contents'>
                                    In 1993, Sungshin Global developed its breakthrough injection technology, an innovation which drastically improved midsole manufacturing efficiency. Reducing the formerly 20-step process down to a mere 3 steps, the technology scaled production quantity to 3,000 pairs per day while requiring only a slim personnel of under 10 employees. The novel injection technology cut raw material loss rate in half, significantly reducing the cost of production.
                                </div>
                            </div>
                            <div className="tech_item">
                                <div className='tech_number'>2</div>
                                <div className='tech_title'>
                                    Development of new injection phylon material
                                </div>
                                <div className='tech_contents'>
                                    Together with its innovative injection technology, Sungshin Global developed EVA-based injection phylon materials allowing for the creation of highly functional midsoles. Sungshin’s EVA materials attained exceptional levels of elasticity and durability. Subsequently developed TPE-based materials combined the strength of rubber with the flexibility of plastic, reaching new possibilities in midsole performance. Sungshin’s innovations in EVA and TPE materials set the stage for the mass production of injection foam midsoles.
                                </div>
                            </div>
                            <div className="tech_item">
                                <div className='tech_number'>3</div>
                                <div className='tech_title'>
                                    The world’s first commercialized injection midsole
                                </div>
                                <div className='tech_contents'>
                                    Through innovations in injection technology and materials, Sungshin increased midsole manufacturing efficiency, reduced production cost, and made headway into more environmentally sustainable midsoles with the use of foam. The world’s first company to commercialize injection foam midsoles, Sungshin Global has realized a milestone in the shoe industry and continues to build upon its legacy today.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact c1">
                <div className='divider_container'>
                    <div className='section_title'>Contact</div>
                    <div className='section_desc'>
                        <div className='contact_items'>
                            <div className='ct_item first_item top_line_item'>
                                <div className='ct_country'>Korea</div>
                                <div className="ct_contents">
                                    <div className='ctc_item'>
                                        <div className='ct_name'>
                                            <span className="strong_t">Headquarters</span> <br />
                                            Sungshin New Material Co.Ltd <br />
                                        </div>
                                        <div className="ct_tel">
                                            B-607 Centumskybiz 97<br />
                                            Centumjungang-ro Haeundae-gu<br />
                                            Busan 48058<br />
                                            T +82 51 927 2001(2)<br />
                                            F +82 51 927 2006<br />
                                            E ssk@sungshinglobal.kr
                                        </div>
                                    </div>
                                    <div className='ctc_item'>
                                        <div className='ct_name'>
                                            <span className="strong_t">R&D Center</span> <br />
                                            Comtech Chemical Co.Ltd
                                        </div>
                                        <div className="ct_tel">
                                            8-48 Bunseong-ro 727 Road<br />
                                            Gimhae-si Gyeongnam 50827<br />
                                            T +82 55 327 9611<br />
                                            F +82 55 327 9616<br />
                                            E ctc@comtechchemical.kr
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='map'>
                            <img src="/img/map.png" />
                        </div>
                            <div className='ct_item top_line_item'>
                                <div className='ct_country'>China</div>
                                <div className="ct_contents">
                                <div className='ct_name'>
                                        Sungshin China <br />
                                        Qingyuan | Hexin | Qingxin | <br />
                                        Qiyang | Nanchang | Guangxi 
                                </div>
                                <div className="ct_tel">
                                    T  +86 763 360 2688  <br />
                                    F  +86 763 360 3972  <br />
                                    E  business@sungshinchina.com
                                </div>
                                </div>
                            </div>
                            <div className='ct_item'>
                                <div className='ct_country'>Thailand</div>
                                <div className="ct_contents">
                                <div className='ct_name'>
                                Thai Sungshin New Materials Co., Ltd.
                                </div>
                                <div className="ct_tel">
                                    T  +66 38 961 850(3) <br />
                                    F  +66 38 961 854 <br />
                                    E  marketing.pan@thaisungshin.com
                                </div>
                                </div>
                            </div>
                            <div className='ct_item'>
                                <div className='ct_country'>Indonesia</div>
                                <div className="ct_contents">
                                <div className='ct_name'>
                                PT. Sung Shin Indonesia <br />
                                PT. Sung Shin Best Indonesia <br />
                                PT. Sung Shin Advanced Indonesia

                                </div>
                                <div className="ct_tel">
                                    T  +62 21 820 4245(7) <br />
                                    F  +62 21 820 4276 <br />
                                    E  ssi@sungshinid.com
                                </div>
                               
                                </div>
                                <div className='video_link_item'>
                                <img onClick={()=>{setVideoPopYn(true)}} src="/img/thumb01.png" />
                                    </div>
                            </div>
                            <div className='ct_item'>
                                <div className='ct_country'>Vietnam</div>
                                <div className="ct_contents">
                                    <div className='ct_name'>
                                        Sungshin Vietnam Co., Ltd.<br />
                                        Sungshin Vietnam A- I<br />
                                        Sungshin Vietnam A-II<br />
                                        Sungshin Vietnam A- III<br />
                                        Sungshin Solution

                                    </div>
                                <div className="ct_tel">
                                    T  +84 8 3897 4198(9) <br />
                                    F  +84 8 3897 4197 <br />
                                    E  ssv@sungshinvn.com
                                </div>
                               
                                </div>
                                <div className='video_link_item'>
                                <img onClick={()=>{setVideoPopYn2(true)}} src="/img/thumb02.png" />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    )


}

export default Main;
