
function Footer() {


    return (
        <footer className='footer'>
            <div className="ft_container">
                <div className="ft_logo"><img src='/img/logo_w.svg'/></div>
            <div className="ft_copyright">
            © 2022 Sungshin Global. All rights reserved. 
            </div>
            </div>

        </footer>
    )


}

export default Footer;
