import { useEffect, useState } from 'react';
import {  useLocation } from "react-router-dom";
import gsap from 'gsap';
import $ from 'jquery';
function Header() {
    const [menuActive,setMenuActive] = useState(false);
    const location = useLocation();
    useEffect(()=>{
        const ani_items = [...document.querySelectorAll('.header .main_menu li')];
        gsap.from('.header .logo_section',0.5,{opacity: 0,y:30,stagger:0.2});
        gsap.from(ani_items,0.5,{opacity: 0,y:30,stagger:0.2});
     
       
        
    },[])

    const menuClick=(target)=>{
      
      
        console.log($(target).offset().top-(window.innerWidth<900?150:68));
        setMenuActive(false);
        $('html, body').animate({
            scrollTop: $(target).offset().top-(window.innerWidth<900?100:68)
         }, 500);
      
    }
    return (
        <header className={'header '+(menuActive?'active':'')+(location.pathname==="/about_fixed"?'fixed':'')}>
            <div className='logo_section'><a href="/"><img src="/img/logo.svg" /></a></div>
            <div className='mobile_menu' onClick={(e)=>{setMenuActive(!menuActive)}}>
                <div className='mm_bar mm1'></div>
                <div className='mm_bar mm2'></div>
                <div className='mm_bar mm3'></div>
            </div>
            <ul className='main_menu'>
                <li className={location.pathname==="/about"?'active':''}><a onClick={()=>{menuClick('.a1')}}>About</a></li>
                <li className={location.pathname==="/business"?'active':''}><a onClick={()=>{menuClick('.b2')}}>Achievements</a></li>
                <li className={location.pathname==="/contact"?'active':''}><a onClick={()=>{menuClick('.c1')}}>Contact</a></li>

            </ul>
         

        </header>
    )


}

export default Header;
